import { getInitialShop, getPage } from 'data/build-data';
import { RegularPage, getStaticProps as getProps, RegularPageProps } from 'layout/page';
import { GetStaticProps } from 'next';
import { ParsedUrlQuery } from 'querystring';

interface Params extends ParsedUrlQuery {
  slug1?: string;
  slug2?: string;
  slug3?: string;
}

export const getStaticProps: GetStaticProps<RegularPageProps, Params> = getProps(getInitialShop, getPage);

export default RegularPage;
